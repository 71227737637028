import './BookingItem.scss';
import ErrorInput from './ErrorInput';

export default ({ isComing, id, form, iteration, errors, linkedGuest, onChangeEmail }) => {
  const { register } = form;
  
  // console.log('>>>', errors);

  const handleChangeEmail = (e) => {
    // onChangeEmail(id, e.target.value)
    // console.log('-->', e.target.value)
  }

  return (
    <div className="booking__item__input__container">
      <input type="hidden" {...register(`people[${id}][ts]`)} value={Date.now()} />
      <input type="hidden" {...register(`people[${id}][coming]`)} value={isComing ? 1: 0} />
      <input type="hidden" {...register(`people[${id}][index]`)} value={iteration} />
      <input type="hidden" {...register(`people[${id}][id]`)} value={id} />
      <input type="hidden" {...register(`people[${id}][link_id]`)} value={linkedGuest.id} />
      <label htmlFor={`people_${id}_lastname`} className="booking__item__input">
        <span>Votre nom</span>
        <input {...register(`people[${id}][lastname]`, { required: true })} id={`people_${id}_lastname`} />
        {errors?.lastname && <ErrorInput>Votre nom est requis.</ErrorInput>}
      </label>

      <label htmlFor={`people_${id}_firstname`} className="booking__item__input">
        <span>Votre prénom</span>
        <input {...register(`people[${id}][firstname]`, { required: true })} id={`people_${id}_firstname`} />
        {errors?.firstname && <ErrorInput>Votre prénom est requis.</ErrorInput>}
      </label>


      {isComing && <label htmlFor={`people_${id}_company`} className="booking__item__input">
        <span>Votre organisation</span>
        <input {...register(`people[${id}][company]`, { required: true })} id={`people_${id}_company`} />
        {errors?.company && <ErrorInput>Le nom de votre organisation est requis.</ErrorInput>}
      </label> }
      
      <label htmlFor={`people_${id}_email`} className="booking__item__input">
        <div className="">
          <span>Votre courriel</span>
          <span>(RGPD compatible promis)</span>
        </div>
        <input type="email" {...register(`people[${id}][email]`, { required: true, onChange: handleChangeEmail })} id={`people_${id}_email`} />
        {errors?.email && <ErrorInput>Votre courriel est requis.</ErrorInput>}
      </label>
    </div>
  );
}