
import './Header.scss';
import logoPaulette from '../components/logo-paulette.svg';
import logoStudioFilmiz from '../components/logo-studio-filmiz.svg';

export default () => {
  return (
    <div className="header__container">
      <div className="header">
        <h1 style={{ display: 'none' }}>La belle bringue</h1>
        <svg className="header__title" viewBox="0 0 681 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3391 0V45.1597H36.276V54.414H0V0H10.3391Z" fill="white"/>
          <path d="M90.6901 54.414H79.6365L75.3229 42.4011H51.2388L47.0151 54.414H36.276L57.2149 0H69.3918L90.6901 54.414ZM56.4959 27.7632L54.429 33.6362H72.1776L70.0208 27.7632C68.2235 23.225 65.9768 16.996 63.2359 9.12091C61.0791 15.4388 58.8325 21.6677 56.5409 27.7632H56.4959Z" fill="white"/>
          <path d="M145.496 25.761C148.529 26.8288 150.849 28.5195 152.499 30.8776C154.15 33.2357 154.998 35.8608 154.998 38.8417C154.998 43.7804 153.392 47.6067 150.135 50.3208C146.879 53.0348 142.195 54.414 135.994 54.414H110.477V0H135.146C140.856 0 145.228 1.29028 148.261 3.91532C151.295 6.54036 152.767 9.87728 152.767 14.0596C152.767 19.3986 150.358 23.3139 145.496 25.761ZM134.388 8.49802H120.826V22.6911H134.388C136.975 22.6911 138.983 22.0237 140.41 20.7334C141.793 19.4431 142.507 17.6189 142.507 15.3498C142.507 13.2142 141.793 11.5235 140.366 10.3222C138.938 9.07642 136.931 8.49802 134.388 8.49802ZM120.826 45.8715H135.458C138.358 45.8715 140.589 45.2041 142.105 43.9139C143.622 42.6236 144.425 40.7549 144.425 38.3523C144.425 33.3247 141.436 30.7887 135.458 30.7887H120.826V45.827V45.8715Z" fill="white"/>
          <path d="M202.816 0V9.26196H172.196V22.0862H199.891V30.9474H172.196V45.1521H202.633V54.414H161.593V0H202.77H202.816Z" fill="white"/>
          <path d="M219.751 0V45.1597H245.688V54.414H209.412V0H219.751Z" fill="white"/>
          <path d="M259.325 0V45.1597H285.262V54.414H248.985V0H259.325Z" fill="white"/>
          <path d="M334.729 0V9.26196H304.109V22.0862H331.804V30.9474H304.109V45.1521H334.546V54.414H293.506V0H334.683H334.729Z" fill="white"/>
          <path d="M389.534 25.761C392.568 26.8288 394.888 28.5195 396.538 30.8776C398.189 33.2357 399.036 35.8608 399.036 38.8417C399.036 43.7804 397.43 47.6067 394.174 50.3208C390.917 53.0348 386.233 54.414 380.032 54.414H354.516V0H379.185C384.895 0 389.267 1.29028 392.3 3.91532C395.334 6.54036 396.806 9.87728 396.806 14.0596C396.806 19.3986 394.397 23.3139 389.534 25.761ZM378.427 8.49802H364.865V22.6911H378.427C381.014 22.6911 383.021 22.0237 384.449 20.7334C385.832 19.4431 386.546 17.6189 386.546 15.3498C386.546 13.2142 385.832 11.5235 384.404 10.3222C382.977 9.07642 380.969 8.49802 378.427 8.49802ZM364.865 45.8715H379.497C382.397 45.8715 384.627 45.2041 386.144 43.9139C387.661 42.6236 388.464 40.7549 388.464 38.3523C388.464 33.3247 385.475 30.7887 379.497 30.7887H364.865V45.827V45.8715Z" fill="white"/>
          <path d="M446.382 39.9867L446.824 48.091C446.868 50.6736 447.443 52.811 448.504 54.414H437.937C437.009 52.9001 436.522 50.7182 436.478 47.9128L436.257 41.946C436.125 38.6509 435.373 36.3354 434.002 35.0441C432.632 33.7527 430.51 33.0848 427.592 33.0848H414.24V54.414H403.983V0H428.52C434.533 0 439.219 1.38039 442.579 4.14117C445.939 6.90195 447.575 10.6424 447.575 15.3624C447.575 21.9081 444.569 26.2274 438.556 28.3648C441.297 29.2553 443.243 30.5912 444.436 32.4614C445.63 34.3316 446.293 36.8252 446.382 39.9867ZM414.284 8.50498V25.0251H427.946C430.819 25.0251 433.074 24.3127 434.666 22.8432C436.257 21.3738 437.053 19.2809 437.053 16.5201C437.053 13.9375 436.213 11.9337 434.577 10.5533C432.941 9.17291 430.598 8.50498 427.548 8.50498H414.284Z" fill="white"/>
          <path d="M464.993 54.414H455.099V0H464.993V54.414Z" fill="white"/>
          <path d="M512.372 14.2375V0H522.705V54.414H510.75L494.996 30.2103C490.687 23.7589 487.351 18.2863 484.895 13.8816C485.126 19.5766 485.219 28.3861 485.219 40.221V54.414H474.886V0H486.841L502.688 24.2038C507.414 31.545 510.75 36.9731 512.696 40.5325C512.464 32.9243 512.372 24.1593 512.372 14.193V14.2375Z" fill="white"/>
          <path d="M558.6 35.1152V26.6641H583.714V56.4465H575.987L574.469 49.1703C570.421 54.8647 564.304 57.7119 556.162 57.7119C548.021 57.7119 541.535 55.0907 536.66 49.803C531.784 44.5154 529.3 37.5556 529.3 28.8333C529.3 20.111 531.784 13.1513 536.798 7.81844C541.811 2.57602 548.573 0 557.082 0C564.12 0 570.007 1.76254 574.699 5.28762C579.345 8.8127 582.104 13.6032 583.07 19.6591H572.077C571.295 16.4052 569.547 13.8292 566.926 11.9762C564.304 10.1233 560.992 9.17424 557.174 9.17424C552.023 9.17424 547.929 10.9368 544.939 14.4619C541.949 17.9869 540.431 22.7774 540.431 28.8785C540.431 34.9796 541.949 39.8153 545.031 43.2952C548.067 46.8203 552.253 48.5828 557.588 48.5828C561.82 48.5828 565.408 47.3174 568.397 44.877C571.387 42.3913 573.043 39.1826 573.273 35.1604H558.6V35.1152Z" fill="white"/>
          <path d="M609.272 57.7119C602.36 57.7119 596.925 55.725 592.968 51.7974C589.012 47.8699 587.012 42.3251 587.012 35.2555V0H597.099V35.3479C597.099 39.6451 598.142 42.8796 600.229 45.0975C602.316 47.3154 605.316 48.4244 609.272 48.4244C613.229 48.4244 616.229 47.3154 618.316 45.0975C620.403 42.8796 621.446 39.6451 621.446 35.3479V0H631.533V35.2555C631.533 42.4637 629.576 48.0085 625.62 51.8898C621.707 55.7712 616.229 57.7119 609.272 57.7119Z" fill="white"/>
          <path d="M681 0V9.26196H650.38V22.0862H678.075V30.9474H650.38V45.1521H680.817V54.414H639.777V0H680.954H681Z" fill="white"/>
        </svg>

        <div className="header__logos">
          <a target="_blank" href="https://vasypaulette.com">
            <img src={logoPaulette} alt="Vas-y Paulette" />
          </a>
          <svg className="header__logos__separation" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.435 14.435L1 1" stroke="white"/>
            <path d="M1.00003 14.435L14.4351 1" stroke="white"/>
          </svg>

          <a target="_blank" href="https://studio-filmiz.fr">
            <img src={logoStudioFilmiz} alt="Studio Filmiz" />
          </a>
        </div>
      </div>
    </div>
  );
}
