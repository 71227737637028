
import './Intro.scss'

export default () => {
  return (
    <section>
      <p className="intro">
        Parce que toute occasion est forcément bonne pour faire la fête, <b>Studio Filmiz & Vas-y Paulette</b> vous invitent pour célébrer leur colocation créative. Au programme : étincelles, bulles et boucan, parce qu'après tout, il faut bien que la bringue soit belle. 
        Alors, rendez-vous <b>jeudi 6 octobre 2022</b> au <a target="_blank" href="https://goo.gl/maps/foVoY8oBdc6HDN7y5"><i>16 avenue de Mérande à Chambéry</i></a>. Début des hostilités : <b>18h30</b>.
      </p>
    </section>
  );
}