
import './App.css';
import './fonts.scss';
import 'normalize.css';
import classNames from 'classnames';
import { useState } from 'react';

import Header from './sections/Header';
import Form from './sections/Form';
import Intro from './sections/Intro';
import Footer from './sections/Footer';
import Finish from './sections/Finish';
import AnimIntro from './sections/AnimIntro';
import ResponseSelector from './sections/ResponseSelector';



function App() {

  const [ step, setStep ] = useState(0);
  const [ isComing, setComing ] = useState(null);


  const handleResponse = (coming = true) => {
    setComing(coming);
  };


  return (
    <div className="App">
      {step === 0 && (
        <>
          <AnimIntro />
          <Header />
          <Intro />
          <section>
            <h1 className="booking__title">Inscription</h1>
            <ResponseSelector onChange={handleResponse} />
            {isComing !== null && <Form key={'isComing_' + isComing} onSuccess={() => setStep(1)} isComing={isComing} />}
          </section>
          <Footer />
        </>
      )}
      {step === 1 && (
        <Finish onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setStep(0)
        }} />
      )}
    </div>
  );
}

export default App;
