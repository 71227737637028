import './Finish.scss';

export default ({ onClick }) => {
  return (
    <div className="finish">
      <a href="#" onClick={onClick}>
        <svg viewBox="0 0 1344 701" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M591.943 604.16C528.65 668.08 445.93 700.04 343.783 700.04C241.636 700.04 158.916 668.08 95.623 604.16C32.3297 539.613 0.683028 455.013 0.683032 350.36C0.683036 245.707 32.3297 161.107 95.623 96.56C158.916 32.64 241.636 0.679958 343.783 0.679958C445.93 0.679958 528.65 32.64 591.943 96.56C655.236 161.107 686.883 245.707 686.883 350.36C686.883 455.013 655.236 539.613 591.943 604.16ZM215.003 505.46C246.963 543.06 289.89 561.86 343.783 561.86C397.05 561.86 440.29 543.06 473.503 505.46C506.09 468.487 522.383 416.787 522.383 350.36C522.383 283.933 506.09 232.233 473.503 195.26C440.29 157.66 397.05 138.86 343.783 138.86C289.89 138.86 246.963 157.66 215.003 195.26C182.416 232.233 166.123 283.933 166.123 350.36C166.123 416.787 182.416 468.487 215.003 505.46ZM1149.66 15.72L1343.3 15.72L1074.46 289.26L1343.3 685H1155.3L962.604 395.48L872.364 484.78L872.364 685H713.504L713.504 15.72L872.364 15.72L872.364 305.24L1149.66 15.72Z" fill="#2C72FF"/>
        </svg>
      </a>
      <a href="#" onClick={onClick}>C'est tout bon, comme Jacques.</a>
    </div>
  );
}